import React, { useState, useEffect, useRef } from 'react';
import { Send, Paperclip, MessageCircle } from 'lucide-react'; // Ícones
import { useNavigate } from 'react-router-dom'; // Para navegação
import Icon from '../assets/Icon.png';
import useFacebookSDK from '../hooks/useFacebookSDK'; // Hook do Facebook
import WhatsAppConfig from './WhatsAppConfig'; // Componente de Configuração do WhatsApp
import axios from 'axios'; // Para chamadas HTTP à API do Google Cloud Functions

const ChatSettings = () => {
  const [messages, setMessages] = useState([]);
  const [currentView, setCurrentView] = useState('chat'); // Controla a visualização atual
  const navigate = useNavigate(); // Hook para navegação
  const messagesEndRef = useRef(null); // Referência para o final das mensagens

  // Carrega o SDK do Facebook com o App ID
  useFacebookSDK('357319013845670', 'v20.0');

  // Função para adicionar uma mensagem
  const addMessage = (message, isUser) => {
    console.log('Adicionando mensagem:', message, 'É usuário?', isUser);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, isUser: isUser },
    ]);
  };

  // Função para rolar automaticamente para a última mensagem
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Rola para a última mensagem sempre que as mensagens mudarem
  useEffect(() => {
    console.log('Mensagens atualizadas:', messages);
    scrollToBottom();
  }, [messages]);

  // Função para chamar o assistente IAT no backend (Google Cloud Functions)
  const callIATAssistant = async (userInput) => {
    try {
      console.log('Chamando assistente IAT com entrada do usuário:', userInput);

      // Envia a mensagem para a função do Google Cloud Functions
      const requestData = {
        userInput: userInput,
        user_id: '123456', // Substitua pelo ID do usuário ou outra identificação
        source: 'chat_settings',
        cnpj: '29035637000103', // CNPJ padrão
      };
      console.log('Dados da requisição:', requestData);

      const response = await axios.post(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json', // Define que o conteúdo é JSON
          },
        }
      );

      console.log('Resposta recebida do backend:', response.data);

      const aiResponse = response.data.message; // A resposta gerada pelo backend
      console.log('Resposta da IA:', aiResponse);

      addMessage(aiResponse, false); // Adiciona a resposta na interface de chat
    } catch (error) {
      console.error('Erro ao se comunicar com o backend:', error);

      if (error.response) {
        console.error('Dados da resposta de erro:', error.response.data);
        console.error('Status da resposta de erro:', error.response.status);
        console.error('Cabeçalhos da resposta de erro:', error.response.headers);
      }

      addMessage(
        'Desculpe, houve um erro ao processar sua solicitação.',
        false
      );
    }
  };

  // Função para lidar com a entrada de texto do usuário
  const handleUserInput = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      const userInput = document.getElementById('user-input').value.trim();
      console.log('Entrada do usuário:', userInput);

      if (userInput) {
        addMessage(userInput, true); // Adiciona a mensagem do usuário à interface
        callIATAssistant(userInput); // Envia a mensagem ao backend
        document.getElementById('user-input').value = ''; // Limpa o campo de entrada
      }
    }
  };

  // Função para ativar o input de arquivo ao clicar no ícone de anexo
  const handleAttach = () => {
    console.log('Botão de anexo clicado');
    document.getElementById('file-input').click(); // Ativa o input de arquivo oculto
  };

  // Função para lidar com o envio de arquivo
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtém o arquivo selecionado
    if (file) {
      console.log('Arquivo selecionado:', file.name);
      addMessage(`Você anexou: ${file.name}`, true); // Adiciona o nome do arquivo como mensagem
    } else {
      console.log('Nenhum arquivo selecionado');
    }
  };

  // Função para lidar com a seleção de uma opção
  const handleOptionClick = (optionText) => {
    console.log('Opção selecionada:', optionText);

    if (optionText === 'Config WhatsApp') {
      setCurrentView('whatsappConfig'); // Muda a visualização para WhatsAppConfig
      console.log('Visualização atual alterada para: whatsappConfig');
    } else {
      addMessage(`Você selecionou: ${optionText}`, true); // Adiciona a seleção do usuário
      setTimeout(() => {
        addMessage(`Ação iniciada para: ${optionText}`, false); // Simula uma ação baseada na opção selecionada
      }, 1000);
    }
  };

  // Função para voltar para o chat principal a partir de outras visualizações
  const handleBack = () => {
    console.log('Botão voltar clicado');
    setCurrentView('chat');
  };

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#242424',
        color: '#e9edef',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Cabeçalho com botão de voltar e logo centralizado */}
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#242424',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {currentView !== 'chat' && (
          <button
            onClick={handleBack}
            className="flex items-center justify-center text-gray-400"
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            {/* Ícone de voltar */}
            <span style={{ marginRight: '8px', fontSize: '16px' }}>←</span>
            <span>Voltar</span>
          </button>
        )}
        {currentView === 'chat' && (
          <button
            onClick={() => navigate('/chat')}
            className="flex items-center justify-center text-gray-400"
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <MessageCircle size={24} /> {/* Ícone de chat atualizado */}
            <span style={{ marginLeft: '8px', fontSize: '16px' }}>
              Painel de Atendimento
            </span>{' '}
            {/* Texto ao lado do ícone */}
          </button>
        )}
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {' '}
          {/* Centralização do logo */}
          <img
            src={Icon}
            alt="Logo"
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'contain',
            }}
          />{' '}
          {/* Logo */}
        </div>
        <div style={{ width: '24px' }}></div>{' '}
        {/* Espaço para equilibrar o layout */}
      </div>

      {/* Renderiza o conteúdo com base na visualização atual */}
      {currentView === 'chat' ? (
        <>
          {/* Container de Opções */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              gap: '10px',
              width: '100%',
            }}
          >
            {/* Opções existentes */}
            <div
              className="option"
              onClick={() => handleOptionClick('Relatórios')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>📊</span>
              <p style={{ margin: 0 }}>Relatórios</p>
            </div>
            <div
              className="option"
              onClick={() => handleOptionClick('MSG Template')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>📝</span>
              <p style={{ margin: 0 }}>Mensagem Template</p>
            </div>
            <div
              className="option"
              onClick={() => handleOptionClick('Config WhatsApp')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>📱</span>
              <p style={{ margin: 0 }}>Configuração do WhatsApp</p>
            </div>
            <div
              className="option"
              onClick={() => handleOptionClick('Disparo Mensagem')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>📨</span>
              <p style={{ margin: 0 }}>Disparo de Mensagem</p>
            </div>
            <div
              className="option"
              onClick={() => handleOptionClick('Config Bot')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>🤖</span>
              <p style={{ margin: 0 }}>Configuração do Bot</p>
            </div>
            <div
              className="option"
              onClick={() => handleOptionClick('Config Usuário')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', marginBottom: '5px' }}>👤</span>
              <p style={{ margin: 0 }}>Configuração do Usuário</p>
            </div>
          </div>

          {/* Container de Mensagens */}
          <div
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: '20px',
              width: '100%',
              maxWidth: '800px',
              backgroundColor: '#242424',
            }}
            id="chat-container"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex mb-4 ${
                  message.isUser ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`rounded-lg p-3 max-w-xs ${
                    message.isUser
                      ? 'bg-[#141414] text-right'
                      : 'bg-[#242424] text-left'
                  } relative`}
                >
                  {/* Texto da mensagem */}
                  <p className="text-white">{message.text}</p>

                  {/* Hora da mensagem */}
                  <span className="text-xs text-gray-300 absolute bottom-1 right-2"></span>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} /> {/* Referência para o final das mensagens */}
          </div>

          {/* Campo de Entrada */}
          <div
            className="p-4 bg-[#242424] flex justify-center"
            style={{ width: '100%', maxWidth: '800px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button
                onClick={handleAttach}
                className="flex items-center justify-center text-gray-400"
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              >
                <Paperclip size={24} />
              </button>
              <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <input
                type="text"
                placeholder="Mensagem Beny"
                id="user-input"
                className="flex-1 p-2 rounded bg-[#242424] text-gray-200 placeholder-gray-400 mx-4"
                onKeyPress={handleUserInput}
              />
              <button
                onClick={handleUserInput}
                className="flex items-center justify-center text-gray-400"
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              >
                <Send size={24} />
              </button>
            </div>
          </div>
        </>
      ) : currentView === 'whatsappConfig' ? (
        <WhatsAppConfig /> // Exibe a visualização de configuração do WhatsApp
      ) : (
        <div style={{ color: '#fff', marginTop: '20px' }}>
          Visualização não encontrada
        </div>
      )}
    </div>
  );
};

export default ChatSettings;
